.dropdown-item {
    text-align: right;
}
.dropdown-toggle {
    &::after {
        margin-left: unset;
        margin-right: .255em;
    }
}
.dropdown-menu-end[data-bs-popper] {
	right: auto;
	left: 0;
}
@include media-breakpoint-down(xl) {
    .dropdown-menu-end[data-bs-popper] {
        right: 0;
        left: auto;
    }
}